import { createRouter, createWebHistory } from 'vue-router';


//Import Pages
import PageHome from '@/Pages/PageHome.vue';
import PageLogin from '@/Pages/PageLogin.vue';
import PageCampingSpot from '@/Pages/PageCampingSpot.vue';
import PageRegister from '@/Pages/PageRegister.vue';
import PageUser from '@/Pages/PageUser.vue';
import OwnerOverview from '@/Pages/Owner/PageOwnerOverview.vue';
import PageBookings from '@/Pages/PageBookings.vue';
//Componets
import UserLogout from '@/components/UserLogout.vue';
import RegisterOwner from "@/components/RegisterOwner.vue"

// Define routes
const routes = [
  {
    path: '/',
    redirect: '/Home'
  },
  {
    path: '/Home',
    name: 'Camping spots',
    component: PageHome
  },
  {
    path: '/CampingSpot/:id',
    name: 'Camping spot',
    component: PageCampingSpot
  },
  {
    path: '/Login',
    name: 'Login',
    component: PageLogin
  },
  {
    path: '/Register',
    name: 'Register',
    component: PageRegister
  },
  {
    path: '/User',
    name: 'User',
    component: PageUser
  },
  {
    path: '/RegisterOwner',
    name: 'RegisterOwner',
    component: RegisterOwner
  },
  {
    path: '/Bookings',
    name: 'Bookings',
    component: PageBookings
  },
  {
    path: '/OwnerOverview',
    name: 'OwnerOverview',
    component: OwnerOverview
  },
  {
    path: '/Logout',
    name: 'UserLogout',
    component: UserLogout
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
