<template>
<div class="masterdiv flex flex-col items-center bg-transparent text-white">
  <img src="../assets/invert_test.png" alt="CampingLogo" class="logo mb-4" /> 
    <h1 class="text-3xl font-bold mb-8">Login</h1>
    <form @submit.prevent="login" class="w-96 max-w-md">
      <div class="input-box mb-6">
        <input
          type="text"
          id="username"
          name="username"
          v-model="credentials.username"
          placeholder="Enter your username"
          class="input-field w-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-80 rounded-md p-3 text-white"
          required
        />
      </div>
      <div class="input-box mb-6">
        <ion-icon name="lock-closed" class="icon absolute right-4 top-1/2 -translate-y-1/2"></ion-icon>
        <input
          type="password"
          id="password"
          name="password"
          v-model="credentials.password"
          placeholder="Enter your password"
          class="input-field w-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-80 rounded-md p-3"
          required
        />
      </div>
      <div class="flex justify-end">
        <button type="submit" class="lgnBtn bg-blue-500 text-white rounded-md p-3 font-bold hover:bg-blue-600">
          LOGIN
        </button>
      </div>
    </form>
    <div class="mt-10 text-center text-lg">
      <div>
        Forgot password?
        <a href="" class="link">Click here.</a>
      </div>
      <div class="mt-2">
        Don't have an account?
        <router-link :to="'/Register'" class="link">Register now.</router-link>
      </div>
    </div>
    <div v-if="errorMessages.length" class="error-messages mt-4 text-center text-lg">
      <div v-for="(message, index) in errorMessages" :key="index" class="error-message text-red-500">
          {{ message }}
    </div>
</div>
</div>
</template>
  
  <script>
  import axios from '@/api/axios';
  
  export default {
    name: 'PageLogin',
    data() {
      return {
        credentials: {
          username: '',
          password: ''
        },
        errorMessages: []
      };
    },
    methods: {
      login() {
        axios.post('/api/Auth/login/user', this.credentials)
        .then(response => {
          if (response.data && response.data.token) {
            this.$store.dispatch('login', {
              token: response.data.token
            });
            this.$store.dispatch('fetchOwnerStatus');
            this.registerOwner();
            this.$router.push('/Home'); // Redirect 
          } else {
            console.error('Unexpected response structure:', response);
            this.errorMessages.push('Unexpected response structure, check console for details.'); // Update this line
          }
        })
        .catch(error => {
                if (error.response) {
                    if (error.response.status === 401) {
                        this.errorMessages.push('Login failed. Please check your username and password and try again.'); // Update this line
                    } else {
                        console.error('Login failed:', error.response.data);
                        this.errorMessages.push('Failed to login: ' + (error.response.data.message || 'Unknown error')); // Update this line
                    }
                } else {
                    console.error('Error sending request:', error.message);
                    this.errorMessages.push('Failed to login: ' + error.message); // Update this line
                }
          });
        },
        registerOwner() {
                //console.log(this.$store.state.token);
                axios.post('/api/Users/register-owner')
                .then(response => {
                    console.log(response.data);
                    console.log(response.data.message);
                    if (response.status === 200 && response.data && response.data.message) {
                        // this.successMessage = 'successfully registered as an owner';
                        //this.showModal = true; 
                        this.$store.dispatch('updateIsOwner', true);
                    } 
                })
                .catch(error => {
                    console.error('Registration failed:', error);
                });
            }
    }
  };
  </script>
  
  <style scoped>
    .masterdiv{
      margin-top: 80px;
    }

    ::placeholder {
      color: rgb(0, 0, 0);
    }

    .input-field {
      background-color: #fdfdfdda;
      border: none;
      color: #000000;
    }
    
    .icon {
      font-size: 20px;
    }
    
    .lgnBtn {
      min-width: unset;
      max-width: unset;
    }

    .logo {
      width: 200px;
      height: auto;
      margin-bottom: 1rem; 
    }

    .link{
      @apply hover:underline;
      color: #22d3ee; 
    }
  </style>
  