<script>
  export default {
    name: "UserLogout",
    created() {
      this.logout();
    },
    methods: {
      logout() {
        this.$store.dispatch('logout'); 
        this.$router.push({ path: '/Home' });
      }
    }
  }
</script>
