<template>
  <div class="flex items-center">
    <span v-for="n in 5" :key="n" class="text-yellow-500 cursor-pointer" @click="setRating(n)">
      <font-awesome-icon v-if="n <= rating" :icon="['fas', 'star']" />
      <font-awesome-icon v-else :icon="['far', 'star']" />
    </span>
  </div>
</template>
  
<script>
export default {
  props: {
    rating: {
      type: Number,
      default: 0
    }
  },
  emits: ['update:rating'],
  methods: {
    setRating(value) {
      this.$emit('update:rating', value);
    }
  }
};
</script>

  
  <style scoped>
  .text-yellow-500 {
    color: #F59E0B;
  }
  </style>
  