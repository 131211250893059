import axios from 'axios';

// const axiosInstance = axios.create({
//   baseURL: process.env.VUE_APP_API_URL,  // Base URL from environment variables
//   headers: {
//     'Content-Type': 'application/json'  
//   }
// });

const axiosInstance = axios.create({
  baseURL: 'http://yggdrasilcampingapi-dev.eba-kpvdtgaw.eu-west-2.elasticbeanstalk.com/',  
  headers: {
    'Content-Type': 'application/json'  
  }
});


axiosInstance.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('authToken');
  if (token) {
      config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default axiosInstance;
