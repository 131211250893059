<template>
     <div v-if="showModal" class="fixed z-10 inset-0 overflow-y-auto">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div class="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div v-if="successMessage" class="text-green-500 mb-4">
                            {{ successMessage }}
                        </div>
                        <div v-else-if="errorMessages.length" class="text-red-500 mb-4">
                            <div v-for="(message, index) in errorMessages" :key="index">
                                {{ message }}
                            </div>
                        </div>
                    </div>
                    <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" @click="showModal = false">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
    import axios from '@/api/axios';

    export default {
        name: "RegisterOwner",
        data() {
            return {
                showModal: false,
                successMessage: '',
                errorMessages: [],
            };
        },
        created() {
            this.registerOwner();
        },
        methods: {
            registerOwner() {
                //console.log(this.$store.state.token);
                axios.post('/api/Users/register-owner')
                .then(response => {
                    console.log(response.data);
                    console.log(response.data.message);
                    if (response.status === 200 && response.data && response.data.message) {
                        console.log('Registration successful', response);
                        this.successMessage = 'successfully registered as an owner';
                        this.showModal = true; 
                        this.$store.dispatch('updateIsOwner', true);
                    } 
                })
                .catch(error => {
                    console.error('Registration failed:', error);
                });
            }
        }
    };
</script>
