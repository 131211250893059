<template>
  <div class="container mx-auto px-4">
    <h1 class="text-2xl font-bold my-4 text-white text-center mb-8">Your Camping Spots</h1>
    <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" style="background-color: #133655be;">
      <button @click="openModalForEdit(null)" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
        Add Spot +
      </button>

      <div v-if="spots.length > 0" class="mt-8">
        <h2 class="text-xl font-bold mb-8 text-white">Your Camping Spots</h2>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4"> 
          <!-- style="height: 320px;" -->
          <div v-for="spot in spots" :key="spot.id" class="bg-white shadow-md rounded px-4 pt-6 pb-4 mb-4" style="width: fit-content;">
            <h3 class="text-lg font-medium text-gray-900">{{ spot.name }}</h3>
            <p class="text-gray-600">{{ spot.location }}</p>

            <div class="carousel relative">
              <div class="carousel-inner relative overflow-hidden w-full" ref="carouselItems">
                <div v-if="spot.pictures && spot.pictures.length > 0">
                      <div v-for="(picture, index) in spot.pictures" :key="index" class="carousel-item" :style="{opacity: index === spot.currentPictureIndex ? '1' : '0'}" :ref="`carouselItems${spot.id}`">
                        <img :src="picture.url" alt="Spot Picture" class="w-full h-48 object-cover rounded mt-2">
                      </div>
                    </div>
                      <div v-else>
                        <img src="https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg" alt="No Image Available" class="h-48 object-fit rounded mt-2" style="margin-left: 24%;">
                  </div>
              </div>
              <button @click="() => nextPicture(spot.id)" class="carousel-control-prev absolute top-0 left-0 flex items-center justify-center px-4 pt-4 pl-3 text-white hover:opacity-75 z-10" type="button">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button @click="() => previousPicture(spot.id)" class="carousel-control-next absolute top-0 right-0 flex items-center justify-center px-4 pt-4 pr-3 text-white hover:opacity-75 z-10" type="button">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>

            <div class="mt-4" style="margin-top: 160px; display: flex;">
              <button @click="() => openModalForEdit(spot)" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                Edit
              </button>
              <button @click="() => deleteSpot(spot.id)" class="ml-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                Delete
              </button>
              <button @click="() => openModalForUpload(spot.id)" class="ml-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                Add Pictures
              </button>
              <button @click="openModalForEditPictures(spot.id)" class="ml-4 bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                Edit Pictures
              </button>
            </div>
            <button @click="() => openModalForBookings(spot.id)" class="w-full bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-3">
              Check Bookings
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Booking Dates Modal -->
    <div v-if="showBookingModal" class="fixed z-10 inset-0 overflow-y-auto">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
              Your camping spot is booked on these dates
            </h3>
            <div class="mt-2">
              <ul v-if="formattedBookingDates.length > 0">
                <li v-for="(date, index) in formattedBookingDates" :key="index" class="text-gray-700 mb-2 bg-gray-200" style="padding: 10px; border-radius: 15px;margin-bottom: 10px;">
                  <strong>Check-in:</strong> {{ date.checkInDate }}<br>
                  <strong>Check-out:</strong> {{ date.checkOutDate }}
                </li>
              </ul>
              <p v-else class="text-gray-700">No bookings yet.</p>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button @click="closeModalForBookings" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Upload Modal -->
    <div v-if="showUploadModal" class="fixed z-10 inset-0 overflow-y-auto">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-upload-title">Upload Pictures</h3>
            <div class="mt-2">
              <input type="file" @change="handleFileChange" multiple class="w-full border border-gray-300 rounded px-2 py-1 text-gray-700">
              <button @click="uploadPictures" :disabled="isLoading" class="mt-3 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                Upload
              </button>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button @click="showUploadModal = false" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Pictures Modal -->
    <div v-if="showEditPicturesModal" class="fixed z-10 inset-0 overflow-y-auto">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-edit-pictures-title">Edit Pictures</h3>
            <div class="mt-2">
              <div v-for="(picture, index) in newSpot.picturesWithIds" :key="index">
                <img :src="picture" alt="Spot Picture">
                <button @click="deletePicture(index)" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline m-3">
                  Delete
                </button>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button @click="showEditPicturesModal = false" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Add/Edit Spot Modal -->
    <div v-if="openAddSpotModal" class="fixed z-10 inset-0 overflow-y-auto">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
              {{ openAddSpotModalForEdit ? 'Edit Camping Spot' : 'Add New Camping Spot' }}
            </h3>
            <div class="mt-2">
              <form @submit.prevent="submitSpot">
                <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="spotName">
                      Spot Name
                    </label>
                    <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="spotName" type="text" placeholder="Spot Name" v-model="newSpot.name" required>
                  </div>
                  <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="location">
                      Location
                    </label>
                    <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="location" type="text" placeholder="Location" v-model="newSpot.location" required>
                  </div>
                  <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="description">
                      Description
                    </label>
                    <textarea class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="description" placeholder="Description" v-model="newSpot.description" required></textarea>
                  </div>
                  <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="price">
                      Price (NOK)
                    </label>
                    <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="price" type="number" placeholder="Price" v-model="newSpot.price" required min="0">
                  </div>
                  <div class="flex -mx-3 mb-6">
                    <div class="w-full px-3">
                      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="availabilityStart">
                        Availability Start
                      </label>
                      <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="availabilityStart" type="datetime-local" placeholder="Availability Start" v-model="newSpot.availabilityStart" required>
                    </div>
                    <div class="w-full px-3">
                      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="availabilityEnd">
                        Availability End
                      </label>
                      <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="availabilityEnd" type="datetime-local" placeholder="Availability End" v-model="newSpot.availabilityEnd" required>
                    </div>
                  </div>
                  <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="maximumCapacity">
                      Max Group Size
                    </label>
                    <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="maximumCapacity" type="number" placeholder="Group Size" v-model="newSpot.maximumCapacity" required min="0">
                  </div>
                  <div class="flex items-center justify-between">
                <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit" :disabled="isLoading.value">
                  {{ isLoading.value? 'Loading...' : openAddSpotModalForEdit? 'Save' : 'Add Spot' }}
                </button>
              </div>
            </form>
          </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button @click="openAddSpotModal = false" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Success Message Modal -->
    <div v-if="successMessage" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">Info</h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">{{ successMessage }}</p>
                </div>
              </div>
            </div>
            <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button @click="successMessage = ''" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from '@/api/axios';

export default {
  name: "OwnerOverview",
  props: ['spot'],
  setup() {
    const spots = ref([]);
    const ownerId = ref(sessionStorage.getItem('ownerId'));
    const isLoading = ref(false);
    const showUploadModal = ref(false);
    const openAddSpotModal = ref(false);
    const openAddSpotModalForEdit = ref(false);
    const currentSpotId = ref(null);
    const newSpot = ref({
      id: null,
      name: '',
      location: '',
      description: '',
      price: 0,
      availabilityStart: '',
      availabilityEnd: '',
      maximumCapacity: 0,
    });
    const selectedFiles = ref([]);
    const successMessage = ref('');
    const showEditPicturesModal = ref(false);
    const selectedSpotId = ref(null);
    const showBookingModal = ref(false);
    const bookingDates = ref([]);
    const formattedBookingDates = ref([]);

    const openModalForBookings = async (spotId) => {
      await fetchBookingDates(spotId);
      showBookingModal.value = true;
    };

    const closeModalForBookings = async () => {
      showBookingModal.value = false;
      bookingDates.value = [];
      formattedBookingDates.value = [];
    };

    const fetchBookingDates = async (spotId) => {
      try {
        const response = await axios.get(`/api/Booking/bookingdates?spotId=${spotId}`);
        bookingDates.value = response.data;
        formatBookingDates();
      } catch (error) {
        console.error('Failed to fetch booking dates:', error);
      }
    };

    const formatBookingDates = () => {
      formattedBookingDates.value = bookingDates.value.map(date => ({
        checkInDate: new Date(date.checkInDate).toLocaleDateString(),
        checkOutDate: new Date(date.checkOutDate).toLocaleDateString()
      }));
    };

    const openModalForEditPictures = async (spotId) => {
      selectedSpotId.value = spotId;
      showEditPicturesModal.value = true;
      const picturesWithIds = await fetchPicturesForSpot_Delete(spotId);
      newSpot.value = {...newSpot.value, picturesWithIds };
    };

    const fetchSpots = async () => {
      isLoading.value = true;
      try {
        const response = await axios.get(`/api/Owner/${ownerId.value}/get-all/camping-spots`);
        spots.value = response.data.map(spot => ({
         ...spot,
          pictures: [],
          currentPictureIndex: 0
        }));
        spots.value.forEach(spot => fetchPicturesForSpot(spot.id));
      } catch (error) {
        console.error('Failed to fetch spots:', error);
      } finally {
        isLoading.value = false;
      }
    };

    const fetchPicturesForSpot = async (spotId) => {
      try {
        const response = await axios.get(`/api/Picture/camping-spot/${spotId}`);
        const spotIndex = spots.value.findIndex(s => s.id === spotId);
        if (spotIndex!== -1) {
          spots.value[spotIndex].pictures = response.data.map(picture => ({
           ...picture,
            url: `/CampingSpotpicture/${picture.url}`
          }));
          spots.value[spotIndex].currentPictureIndex = 0;
        }
      } catch (error) {
        console.error(`Failed to fetch pictures for spot ${spotId}:`, error);
      }
    };

    const fetchPicturesForSpot_Delete = async (spotId) => {
      try {
        const response = await axios.get(`/api/Picture/camping-spot/${spotId}`);
        const picturesWithIds = {};
        response.data.forEach(picture => {
          picturesWithIds[picture.id] = `/CampingSpotpicture/${picture.url}`;
        });
        return picturesWithIds;
      } catch (error) {
        console.error(`Failed to fetch pictures for spot ${spotId}:`, error);
        return {};
      }
    };

    const openModalForUpload = (spotId) => {    
      currentSpotId.value = spotId;
      showUploadModal.value = true;
      openAddSpotModalForEdit.value = false; 
    };

    const openModalForEdit = (spot) => {
      newSpot.value = spot? {...spot} : {}; 
      openAddSpotModalForEdit.value = true;
      openAddSpotModal.value = true; 
      currentSpotId.value = spot? spot.id : null; 
    };

    const deleteSpot = async (spotId) => {
      if (!confirm('Are you sure you want to delete this spot?')) return;
      isLoading.value = true;
      try {
        await axios.delete(`/api/Owner/${ownerId.value}/delete/camping-spots/${spotId}`);
        successMessage.value = 'Spot deleted successfully!';
        fetchSpots();
      } catch (error) {
        console.error('Failed to delete spot:', error);
        successMessage.value = 'Failed to delete spot.';
      } finally {
        isLoading.value = false;
      }
    };

    const submitSpot = async () => {
      isLoading.value = true;
      const formData = new FormData();

      for (const key in newSpot.value) {
        formData.append(key, newSpot.value[key]);
      }

      const startDate = new Date(newSpot.value.availabilityStart);
      const endDate = new Date(newSpot.value.availabilityEnd);
      const now = new Date();

      // Check if dates are not in the past
      if (startDate < now || endDate < now) {
        successMessage.value = 'Dates cannot be in the past.';
        isLoading.value = false;
        return; 
      }

      // Check if start date is before end date
      if (startDate >= endDate) {
        successMessage.value = 'Start date must be before end date.';
        isLoading.value = false;
        return; 
      }

      let endpoint = `/api/Owner/${ownerId.value}/add/camping-spots`; 
      let method = 'post'; 

      if (currentSpotId.value !== null) {
        endpoint = `/api/Owner/${ownerId.value}/update/camping-spots/${currentSpotId.value}`;
        method = 'put';
      }

      try {
        const response = await axios[method](endpoint, formData);
        if (currentSpotId.value === null) { 
          currentSpotId.value = response.data.id; 
        }
        successMessage.value = currentSpotId.value !== null ? 'Spot updated successfully!' : 'Spot added successfully!';
        openAddSpotModal.value = false;
        await fetchSpots();
      } catch (error) {
        console.error('Failed to submit spot:', error);
        successMessage.value = 'Failed to submit spot.';
      } finally {
        isLoading.value = false;
      }
    };

    const deletePicture = async (pictureId) => {
      isLoading.value = true;
      try {
        await axios.delete(`/api/Picture/${pictureId}`);
        successMessage.value = 'Picture deleted successfully!';
        showEditPicturesModal.value = false;
      } catch (error) {
        console.error('Failed to delete picture:', error);
        successMessage.value = 'Failed to delete picture.';
      } finally {
        isLoading.value = false;
      }
    };

    const handleFileChange = (event) => {
      selectedFiles.value = event.target.files;
    };

    const uploadPictures = async () => {
      if (!selectedFiles.value || selectedFiles.value.length === 0) {
        alert('Please select files to upload.');
        return;
      }

      const formData = new FormData();
      for (let file of selectedFiles.value) {
        formData.append('pictures', file);
      }

      isLoading.value = true;
      try {
        await axios.post(`/api/Picture/camping-spot/${currentSpotId.value}/new`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        showUploadModal.value = false;
        successMessage.value = 'Pictures uploaded successfully!';
        fetchPicturesForSpot(currentSpotId.value);
        selectedFiles.value = [];
        formData.delete('pictures');
      } catch (error) {
        console.error('Error uploading pictures:', error);
        successMessage.value = 'Failed to upload pictures.';
      } finally {
        isLoading.value = false;
      }
    };

    const nextPicture = (spotId) => {
      const spotIndex = spots.value.findIndex(s => s.id === spotId);
      if (spotIndex!== -1) {
        const spot = spots.value[spotIndex];
        spot.currentPictureIndex = (spot.currentPictureIndex + 1) % spot.pictures.length;
      }
    };

    const previousPicture = (spotId) => {
      const spotIndex = spots.value.findIndex(s => s.id === spotId);
      if (spotIndex!== -1) {
        const spot = spots.value[spotIndex];
        const length = spot.pictures.length;
        spot.currentPictureIndex = (spot.currentPictureIndex - 1 + length) % length;
      }
    };

    onMounted(fetchSpots);

    return {
      spots,
      isLoading,
      showUploadModal,
      openAddSpotModal,
      openAddSpotModalForEdit,
      currentSpotId,
      newSpot,
      selectedFiles,
      successMessage,
      submitSpot,
      deleteSpot,
      openModalForUpload,
      fetchSpots,
      fetchPicturesForSpot,
      openModalForEdit,
      handleFileChange,
      uploadPictures,
      nextPicture,
      previousPicture,
      showEditPicturesModal,
      deletePicture,
      openModalForEditPictures,
      showBookingModal,
      openModalForBookings,
      closeModalForBookings,
      fetchBookingDates,
      bookingDates,
      formattedBookingDates,
    };
  },
};
</script>


<style scoped>
  .carousel-inner {
    overflow: visible;
    height: 48px; 
  }

 .carousel-item {
    position: absolute;
    width: 100%;
    height: 100%; 
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

 .carousel-item.active {
    opacity: 1;
  }
  
 .carousel-control-prev,
 .carousel-control-next {
    transform: translateY(-50%);
    top: 50%;
    transform: translateY(-50%);
    background: #174464d9;
    width: 72px;
    border-radius: 10px;
    padding: 2px;
    margin: 10px;
  }

  .spot-actions {
    margin-top: 20px; 
    display: flex;
    justify-content: space-between;
  }
</style>