import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './vuex/store';
import './tailwind.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faMapMarkerAlt, faCalendarAlt, faEuroSign, faUsers, faStar as fasStar, faCoins, faSearch} from '@fortawesome/free-solid-svg-icons';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import { createVfm, ModalsContainer } from 'vue-final-modal';

library.add(faMapMarkerAlt, faCalendarAlt, faEuroSign, faUsers, fasStar, farStar, faCoins, faSearch);

const app = createApp(App);

app.component('font-awesome-icon', FontAwesomeIcon);

app.component('ModalsContainer', ModalsContainer);

app.use(router);

app.use(store);

const vfm = createVfm();
app.use(vfm);

const token = localStorage.getItem('authToken');
if (token) {
  store.commit('setAuth', { isLoggedIn: true, token });
}

app.mount('#app');
