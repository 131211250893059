// src/store/index.js
import { createStore } from 'vuex';
import axios from '@/api/axios';


const store = createStore({
  state() {
    return {
      isLoggedIn: false,
      isOwner: sessionStorage.getItem('isOwner') === 'true' || false,
      user: null,
      token: sessionStorage.getItem('authToken') || null,
      ownerId: null,
    };
  },
  mutations: {
    setAuth(state, payload) {
      state.isLoggedIn = payload.isLoggedIn;
      state.user = payload.user;
      state.token = payload.token;
      sessionStorage.setItem('authToken', payload.token);
    },
    setIsOwner(state, isOwner) {
      state.isOwner = isOwner;
      sessionStorage.setItem('isOwner', isOwner); 
    },
    setOwnerId(state, ownerId) {  
      sessionStorage.setItem('ownerId', ownerId);  
      state.ownerId = ownerId;
    },
  },
  actions: {
    login({ commit }, { user, token }) {
      commit('setAuth', { isLoggedIn: true, user, token });
    },
    logout({ commit }) {
      commit('setAuth', { isLoggedIn: false, user: null, token: null });
      sessionStorage.removeItem('authToken');
      sessionStorage.removeItem('isOwner'); 
      localStorage.removeItem('authToken');
      sessionStorage.removeItem('ownerId');  
      console.log(sessionStorage.getItem('authToken')); 
    },
    fetchOwnerStatus({ commit }) {
      axios.get('/api/Users/check-owner')
        .then(response => {
          if (response.data.ownerId) {
            console.log(response.data.ownerId);
            commit('setOwnerId', response.data.ownerId);
            commit('setIsOwner', true);
          } else {
            commit('setIsOwner', false);
            commit('setOwnerId', null);
          }
        })
        .catch(error => {
          console.error('Failed to fetch owner status', error);
          commit('setIsOwner', false);
          commit('setOwnerId', null);
        });
    }
  }
});

export default store;
