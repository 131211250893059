<template>
    <div class="masterdiv flex flex-col items-center bg-transparent text-white">
     <img src="../assets/invert_test.png" alt="CampingLogo" class="logo mb-4" /> 
        <h1 class="text-3xl font-bold mb-8">Register</h1>
        <form @submit.prevent="register" class="w-96 max-w-md">
          <div class="input-box mb-6">
            <input
              type="text"
              id="username"
              name="username"
              v-model="credentials.username"
              placeholder="Username"
              class="input-field w-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-80 rounded-md p-3 text-white"
              required
            />
          </div>
          <div class="input-box mb-6">
            <input
              type="password"
              id="password"
              name="password"
              v-model="credentials.password"
              placeholder="Password"
              class="input-field w-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-80 rounded-md p-3"
              required
            />
          </div>
          <div class="input-box mb-6">
            <input
              type="email"
              id="email"
              name="email"
              v-model="credentials.email"
              placeholder="Email"
              class="input-field w-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-80 rounded-md p-3 text-white"
              required
            />
          </div>
          <div class="input-box mb-6">
            <input
              type="text"
              id="firstName"
              name="firstName"
              v-model="credentials.firstName"
              placeholder="First Name"
              class="input-field w-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-80 rounded-md p-3 text-white"
              required
            />
          </div>
          <div class="input-box mb-6">
            <input
              type="text"
              id="lastName"
              name="lastName"
              v-model="credentials.lastName"
              placeholder="Last Name"
              class="input-field w-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-80 rounded-md p-3 text-white"
              required
            />
          </div>
          <div class="input-box mb-6">
            <input
              type="file"
              id="profilePicture"
              name="profilePicture"
              @change="onFileChange"
              class="input-field w-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-80 rounded-md p-3 text-white"
              required
            />
          </div>
          <div class="flex justify-end">
            <button type="submit" class="lgnBtn bg-blue-500 text-white rounded-md p-3 font-bold hover:bg-blue-600">
              REGISTER
            </button>
          </div>
        </form>
        <div v-if="errorMessages.length" class="error-messages mt-4 text-center text-lg">
            <div v-for="(message, index) in errorMessages" :key="index" class="error-message text-red-500">
                {{ message }}
            </div>
        </div>
        <div class="mt-10 text-center text-lg">
          <div>
            Already have an account?
            <router-link :to="'/Login'" class="link">Login now.</router-link>
          </div>
        </div>
    </div>
</template>

<script>
import axios from '@/api/axios';

export default {
 name: 'PageRegister',
 data() {
    return {
      credentials: {
        username: '',
        password: '',
        email: '',
        firstName: '',
        lastName: '',
        profilePicture: null
      },
      errorMessages: [] 
    };
 },
 methods: {
    onFileChange(e) {
      this.credentials.profilePicture = e.target.files[0];
    },
    sendWelcomeEmail(email, firstname, lastname) {
      const queryParams = new URLSearchParams();
      queryParams.append('email', email);
      queryParams.append('firstname', firstname);
      queryParams.append('lastname', lastname);

      axios.post('/api/Email/send/welcome', {}, {
        params: queryParams 
      })
      .then(response => {
        if (response.data == "Welcome email sent successfully.") {
          console.log('Send:', response.data);
        } else {
          console.error('Failed to send welcome email:', response.data);
        }
      })
      .catch(error => {
        if (error.response) {
            console.error('Error sending welcome email:', error.response.data);
        } else {
            console.error('Error sending welcome email:', error.message);
        }
      });
    },
    register() {
      const formData = new FormData();
      formData.append('username', this.credentials.username);
      formData.append('password', this.credentials.password);
      formData.append('email', this.credentials.email);
      formData.append('firstName', this.credentials.firstName);
      formData.append('lastName', this.credentials.lastName);
      formData.append('profilePicture', this.credentials.profilePicture);

      axios.post('/api/Auth/register/user', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
        console.log('Registration successful:', response.data);
        this.sendWelcomeEmail(this.credentials.email, this.credentials.firstName, this.credentials.lastName);
        this.$router.push('/Login'); 
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data.PasswordTooShort || error.response.data.PasswordRequiresDigit || error.response.data.PasswordRequiresUpper || error.response.data.PasswordRequiresNonAlphanumeric) {
            this.errorMessages = [];
            if (error.response.data.PasswordTooShort) {
              this.errorMessages.push(...error.response.data.PasswordTooShort);
            }
            if (error.response.data.PasswordRequiresDigit) {
              this.errorMessages.push(...error.response.data.PasswordRequiresDigit);
            }
            if (error.response.data.PasswordRequiresUpper) {
              this.errorMessages.push(...error.response.data.PasswordRequiresUpper);
            }
            if (error.response.data.PasswordRequiresNonAlphanumeric) {
              this.errorMessages.push(...error.response.data.PasswordRequiresNonAlphanumeric);
            }
          } else {
            this.errorMessages = [];
            if (error.response.data.email) {
              this.errorMessages.push(error.response.data.email);
            } 
            
            if(error.response.data.username) {
              this.errorMessages.push(error.response.data.username);            
            } 
          }
        } else {
          this.errorMessages = ['Failed to register: ' + error.message];
        }
        console.error('Registration failed:', this.errorMessages);
      });
    },
 }
};
</script>

<style scoped>
    .masterdiv{
      margin-top: 80px;
    }

    ::placeholder {
      color: rgb(0, 0, 0);
    }

    .input-field {
      background-color: #fdfdfdda;
      border: none;
      color: #000000;
    }
    
    .icon {
      font-size: 20px;
    }
    
    .lgnBtn {
      min-width: unset;
      max-width: unset;
    }

    .logo {
      width: 200px;
      height: auto;
      margin-bottom: 1rem; 
    }

    .link{
      @apply hover:underline;
      color: #22d3ee; 
    }
</style>