<template>
  <div class="container mx-auto px-4">
      <h1 class="text-2xl font-bold my-4 text-white text-center mb-8">Yggdrasil Camping Spots</h1>
      <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" style="background-color: #133655be;">
        <div class="filter-menu px-6 py-2 mb-8 flex items-center space-x-4">
            <div class="filter-item flex-1">
                <div class="flex space-x-2">
                    <p class="p-2 rounded-full border border-gray-300 bg-white" style="text-align: center; width: 60px"><font-awesome-icon icon="search" class="mr-1" /></p>
                    <input type="text" v-model="searchQuery" placeholder="Search" class="w-full p-2 rounded-full border border-gray-300" style="text-align: center;">
                </div>
            </div>
            
            <div class="filter-item flex-1">
                <div class="flex space-x-2">
                    <p class="p-2 rounded-full border border-gray-300 bg-white" style="text-align: center; width: 60px"><font-awesome-icon icon="map-marker-alt" class="mr-1" /></p>
                    <select v-model="locationFilter" class="w-full p-2 rounded-full border border-gray-300" style="text-align: center;">
                        <option value="">All Locations</option>
                        <option v-for="location in uniqueLocations" :key="location" :value="location">{{ location }}</option>
                    </select>
                </div>
            </div>
  
            <div class="filter-item flex-1">
                <div class="flex space-x-2">
                    <p class="w-full p-2 rounded-full border border-gray-300 bg-white" style="text-align: center;">Price range <font-awesome-icon icon="coins"/></p>
                    <input type="number" v-model="minPrice" min="0" placeholder="Min" class="w-full p-2 rounded-full border border-gray-300" style="text-align: center;">
                    <input type="number" v-model="maxPrice" min="0" placeholder="Max" class="w-full p-2 rounded-full border border-gray-300" style="text-align: center;">
                </div>
            </div>
        </div>
          <div v-if="filteredSpots.length > 0" class="mt-8">
              <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                  <div v-for="spot in filteredSpots" :key="spot.id" class="bg-white shadow-md rounded px-4 pt-6 pb-4 mb-4">
                      <h3 class="text-lg font-medium text-gray-900">{{ spot.name }}</h3>
                      <p class="text-gray-600">{{ spot.location }}</p>

                      <div class="carousel relative">
                          <div class="carousel-inner relative overflow-hidden w-full" ref="carouselItems">
                              <div v-if="spot.pictures && spot.pictures.length > 0">
                                  <div v-for="(picture, index) in spot.pictures" :key="index" class="carousel-item" :style="{ opacity: index === spot.currentPictureIndex ? '1' : '0' }" :ref="`carouselItems${spot.id}`">
                                      <img :src="picture.url" alt="Spot Picture" class="w-full h-48 object-cover rounded mt-2">
                                  </div>
                              </div>
                              <div v-else>
                                  <img src="https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg" alt="No Image Available" class="h-48 object-fit rounded mt-2" style="margin-left: 14%;">
                              </div>
                          </div>
                          <button @click="() => previousPicture(spot.id)" class="carousel-control-prev absolute top-0 left-0 flex items-center justify-center px-4 pt-4 pl-3 text-white hover:opacity-75 z-10" type="button">
                              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                              <span class="visually-hidden">Previous</span>
                          </button>
                          <button @click="() => nextPicture(spot.id)" class="carousel-control-next absolute top-0 right-0 flex items-center justify-center px-4 pt-4 pr-3 text-white hover:opacity-75 z-10" type="button">
                              <span class="carousel-control-next-icon" aria-hidden="true"></span>
                              <span class="visually-hidden">Next</span>
                          </button>
                      </div>

                      <div class="mt-4" style="margin-top: 160px; display: flex; align-items: center; justify-content: space-between; width: 100%;">
                            <router-link :to="`/CampingSpot/${spot.id}`" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                                More information
                            </router-link>
                            <p class="text-gray-600 font-medium" style="margin-left: auto;">{{ spot.price }} NOK / Night</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import axios from '@/api/axios';

export default {
  name: "PageHome",
  setup() {
      const spots = ref([]);
      const searchQuery = ref('');
      const locationFilter = ref('');
      const minPrice = ref(0);
      const maxPrice = ref(500);

      const fetchSpots = async () => {
            try {
                const response = await axios.get(`/api/CampingSpot/get-all`);

                spots.value = response.data.map(spot => {
                    if (spot.price > maxPrice.value) {
                        maxPrice.value = spot.price;
                    }
                    return {
                        ...spot,
                        pictures: [],
                        currentPictureIndex: 0
                    };
                });

                spots.value.forEach(spot => fetchPicturesForSpot(spot.id));
            } catch (error) {
                console.error('Failed to fetch spots:', error);
            }
        };

      const fetchPicturesForSpot = async (spotId) => {
          try {
              const response = await axios.get(`/api/Picture/camping-spot/${spotId}`);
              const spotIndex = spots.value.findIndex(s => s.id === spotId);
              if (spotIndex !== -1) {
                  spots.value[spotIndex].pictures = response.data.map(picture => ({
                      ...picture,
                      url: `/CampingSpotpicture/${picture.url}`
                  }));
                  spots.value[spotIndex].currentPictureIndex = 0;
              }
          } catch (error) {
              console.error(`Failed to fetch pictures for spot ${spotId}:`, error);
          }
      };

      const nextPicture = (spotId) => {
          const spotIndex = spots.value.findIndex(s => s.id === spotId);
          if (spotIndex !== -1) {
              const spot = spots.value[spotIndex];
              spot.currentPictureIndex = (spot.currentPictureIndex + 1) % spot.pictures.length;
          }
      };

      const previousPicture = (spotId) => {
          const spotIndex = spots.value.findIndex(s => s.id === spotId);
          if (spotIndex !== -1) {
              const spot = spots.value[spotIndex];
              const length = spot.pictures.length;
              spot.currentPictureIndex = (spot.currentPictureIndex - 1 + length) % length;
          }
      };

      const uniqueLocations = computed(() => {
          const locations = spots.value.map(spot => spot.location);
          return [ ...new Set(locations)];
      });

      const filteredSpots = computed(() => {
          let filtered = [...spots.value];
          if (searchQuery.value) {
              filtered = filtered.filter(spot =>
                  spot.name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
                  spot.location.toLowerCase().includes(searchQuery.value.toLowerCase())
              );
          }
          if (locationFilter.value && locationFilter.value !== 'All Locations') {
              filtered = filtered.filter(spot => spot.location.includes(locationFilter.value));
          }
          filtered = filtered.filter(spot => spot.price >= minPrice.value && spot.price <= maxPrice.value);
          return filtered;
      });

      const applyFilters = () => {
          // Trigger the computed property to update the filtered spots
      };

      onMounted(fetchSpots);

      return {
          spots,
          searchQuery,
          locationFilter,
          minPrice,
          maxPrice,
          uniqueLocations,
          filteredSpots,
          fetchSpots,
          fetchPicturesForSpot,
          nextPicture,
          previousPicture,
          applyFilters
      };
  },
};
</script>

<style scoped>
.carousel-inner {
  overflow: visible;
  height: 48px; 
}

.carousel-item {
  position: absolute;
  width: 100%;
  height: 100%; 
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.carousel-item.active {
  opacity: 1;
}

.carousel-control-prev,
.carousel-control-next {
  top: 50%;
  transform: translateY(-50%);
  background: #174464d9;
  width: 72px;
  border-radius: 10px;
  padding: 2px;
  margin: 10px;
}

.spot-actions {
  margin-top: 20px; 
  display: flex;
  justify-content: space-between;
}
</style>
