<template>
    <div class="container mx-auto px-4">
      <h1 class="text-2xl font-bold my-4 text-white text-center mb-8">Yggdrasil Camping Spots</h1>
      <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" style="background-color: #133655be;">
          <div v-if="bookings.length > 0" class="mt-8">
            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              <div v-for="booking in bookings" :key="booking.bookingId" class="bg-white shadow-md rounded px-4 pt-6 pb-4 mb-4">
                <h3 class="text-lg font-medium text-gray-900">{{ booking.campingSpotDetails.name }}</h3>
                <p class="text-gray-600">Booking Date: {{ new Date(booking.bookingDate).toLocaleDateString() }}</p>
                <p class="text-gray-600">Check-In: {{ new Date(booking.checkInDate).toLocaleDateString() }}</p>
                <p class="text-gray-600">Check-Out: {{ new Date(booking.checkOutDate).toLocaleDateString() }}</p>
                <p class="text-gray-600">Total Price: {{ booking.totalPrice }} NOK</p>
                <button @click="confirmCancel(booking.bookingId)" class="mt-4 bg-red-500 text-white py-2 px-4 rounded hover:bg-red-700">
                  Cancel Booking
                </button>
              </div>
            </div>
          </div>
          <div v-else>
            <p class="text-white text-center">No bookings found.</p>
          </div>
      
          <div v-if="showModal" class="fixed z-10 inset-0 overflow-y-auto">
            <div class="flex items-center justify-center min-h-screen">
              <div class="fixed inset-0 transition-opacity">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
              </div>
              <div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
                <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div class="sm:flex sm:items-start">
                    <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <h3 class="text-lg leading-6 font-medium text-gray-900">Confirm Cancellation</h3>
                      <div class="mt-2">
                        <p class="text-sm text-gray-500">Are you sure you want to cancel this booking?</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button @click="deleteBooking" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 sm:ml-3 sm:w-auto sm:text-sm">
                    Yes, Cancel
                  </button>
                  <button @click="showModal = false" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                    No, Keep Booking
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </template>
  
  <script>
  import axios from '@/api/axios';
  import { ref, onMounted } from 'vue';
  
  export default {
    name: "UserBookings",
    setup() {
      const bookings = ref([]);
      const isLoading = ref(false);
      const showModal = ref(false);
      const bookingToCancel = ref(null);
  
      const fetchBookings = async () => {
        isLoading.value = true;
        try {
          const response = await axios.get('/api/Booking/user/bookings', {});
          bookings.value = response.data;
        } catch (error) {
          console.error('Failed to fetch bookings:', error);
        } finally {
          isLoading.value = false;
        }
      };
  
      const confirmCancel = (bookingId) => {
        bookingToCancel.value = bookingId;
        showModal.value = true;
      };
  
      const deleteBooking = async () => {
        if (bookingToCancel.value !== null) {
          try {
            await axios.delete(`/api/Booking/delete/${bookingToCancel.value}`);
            bookings.value = bookings.value.filter(booking => booking.bookingId !== bookingToCancel.value);
          } catch (error) {
            console.error('Failed to delete booking:', error);
          } finally {
            showModal.value = false;
            bookingToCancel.value = null;
          }
        }
      };
  
      onMounted(fetchBookings);
  
      return {
        bookings,
        isLoading,
        showModal,
        bookingToCancel,
        confirmCancel,
        deleteBooking,
      };
    }
  };
  </script>
  