<template>
  <div>
    <div class="py-6 flex justify-center sm:py-12 mt-10">
      <!-- Gradient Background -->
      <div class="relative py-3 sm:max-w-5xl sm:mx-auto" style="height: fit-content;">
        <div class="absolute inset-0 bg-gradient-to-r from-green-400 to-blue-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
        <!-- Content Container -->
        <div class="relative flex flex-col bg-white shadow-lg sm:rounded-3xl sm:p-20">
          <div class="flex" style="align-items: stretch;">
            <!-- Left Side: Camping Spot Details -->
            <div class="p-4" style="width:400px;">
              <div class="block pl-2 font-semibold text-xl self-start text-gray-700">
                <h2 v-if="spot.name" class="leading-relaxed">{{ spot.name }}</h2>
              </div>
              <div class="divide-y divide-gray-200">
                <div>
                  <div v-if="spot && Object.keys(spot).length">
                    <!-- Images Carousel -->
                    <div class="carousel relative">
                      <div class="carousel-inner relative overflow-hidden w-full" ref="carouselItems">
                        <div v-if="spot.pictures && spot.pictures.length > 0">
                          <div v-for="(picture, index) in spot.pictures" :key="index" class="carousel-item" :style="{opacity: index === spot.currentPictureIndex ? '1' : '0'}" :ref="`carouselItems${spot.id}`">
                            <img :src="picture.url" alt="Spot Picture" class="w-full h-48 object-cover rounded mt-2">
                          </div>
                        </div>
                        <div v-else>
                          <img src="https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg" alt="No Image Available" class="h-48 object-fit rounded mt-2" style="margin-left: 24%;">
                        </div>
                      </div>
                      <button @click="nextPicture" class="carousel-control-prev absolute top-0 left-0 flex items-center justify-center px-4 pt-4 pl-3 text-white hover:opacity-75 z-10" type="button">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                      </button>
                      <button @click="previousPicture" class="carousel-control-next absolute top-0 right-0 flex items-center justify-center px-4 pt-4 pr-3 text-white hover:opacity-75 z-10" type="button">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                      </button>
                    </div>
                    <!-- Spot Details -->
                    <h3 class="text-lg leading-6 text-gray-900 mt-8" style="margin-top: 170px;">Details</h3>
                    <dl class="grid gap-4 mt-2">
                      <div>
                        <dt class="sr-only">Location</dt>
                        <dd class="text-gray-500 truncate">
                          <p class="flex justify-between">
                            <span><font-awesome-icon icon="map-marker-alt" class="mr-1" />Location:</span>
                            <span>{{ spot.location }}</span>
                          </p>
                        </dd>
                        <dt class="sr-only">Price</dt>
                        <dd class="text-gray-500 truncate">
                          <p class="flex justify-between">
                            <span><font-awesome-icon icon="coins" class="mr-1" />Price per night:</span>
                            <span>{{ spot.price }} NOK</span>
                          </p>
                        </dd>
                        <dt class="sr-only">Availability</dt>
                        <dd class="text-gray-500 truncate">
                          <p class="flex justify-between">
                            <span><font-awesome-icon icon="calendar-alt" class="mr-1" />Available from:</span>
                            <span>{{ formatDate(spot.availabilityStart) }} to {{ formatDate(spot.availabilityEnd) }}</span>
                          </p>
                        </dd>
                        <dd class="text-gray-500 truncate">
                          <p class="flex justify-between">
                            <span><font-awesome-icon icon="users" class="mr-1" />Max group size:</span>
                            <span>{{ spot.maximumCapacity }}</span>
                          </p>
                        </dd>
                      </div>
                    </dl>
                    <dt class="sr-only">Description</dt>
                    <dd class="text-sm leading-5 text-gray-500 truncate mt-6" style="width: 100%;">
                        <div class="bg-gray-200 p-4 rounded-lg" style="width: 100%; max-width: 100%; word-wrap: break-word; overflow-wrap: break-word;">
                            <h4 class="font-semibold text-gray-700 mb-2">About {{ spot.name }}:</h4>
                            <p style="word-wrap: break-word; overflow-wrap: break-word; white-space: normal;">{{ spot.description }}</p>
                        </div>
                    </dd>
                  </div>
                  <div v-else>Loading...</div>
                </div>
              </div>
            </div>
            <!-- Right Side: User Feedback -->
            <div class="p-4 ml-8 bg-gray-200 rounded-lg" style="margin: 1rem; overflow: auto; width: 300px;">
              <h3 class="text-lg leading-6 font-medium text-gray-900">User Reviews</h3>
              <div v-if="comments.length > 0" class="mt-4 space-y-4">
                <div v-for="(comment, index) in comments" :key="index" class="p-4 bg-white rounded-lg shadow">
                  <div class="flex items-center space-x-4">
                    <div class="flex justify-between items-center w-full">
                      <p class="font-semibold">{{ comment.userName || "Unknown user"}}</p>
                      <p class="text-sm text-gray-500">{{ formatDate(comment.date) }}</p>
                    </div>
                  </div>
                  <div class="mt-2">
                    <StarRating :rating="comment.rating.ratingValue || 0" />
                  </div>
                  <div class="mt-2">
                    <p class="text-gray-700">{{ comment.commentText }}</p>
                  </div>
                </div>
              </div>
              <p v-else class="text-gray-500">No reviews yet.</p>
            </div>
          </div>
          <!-- Bottom Menu -->
          <div class="w-full">
            <div v-if="isLoggedIn" class="flex justify-between p-4 bg-gray-200 rounded-lg" style="margin: 1rem">
              <button @click="openCalendarModal" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Check availability</button>
              <button @click="openReviewModal" class="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">{{ userHasReview ? 'Change Review' : 'Add Review' }}</button>
            </div>
            <div v-else class="relative">
              <div class="flex justify-between p-4 bg-gray-200 rounded-lg blur-sm">
                <button class="bg-blue-500 text-white px-4 py-2 rounded">Check availability</button>
                <button class="bg-green-500 text-white px-4 py-2 rounded">Review</button>
              </div>
              <div class="absolute inset-0 flex items-center justify-center">
                <p class="bg-white bg-opacity-75 text-gray-700 px-4 py-2 rounded"><router-link :to="'/Login'" class="link" style="color:rgba(59,130,246);">Login</router-link> to access this menu</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Review modal -->
    <div v-if="showReviewModal" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                  {{ userHasReview ? 'Update Review' : 'Leave a Review' }}
                </h3>
                <div class="mt-4">
                  <div class="mt-4">
                    <div class="space-x-2 text-yellow-500">
                      <span v-for="n in 5" :key="n" @click="selectStar(n)" class="cursor-pointer">
                        <font-awesome-icon :icon="[selectedStars >= n ? 'fas' : 'far', 'star']" />
                      </span>
                    </div>
                  </div>
                </div>
                <div class="mt-2">
                  <textarea v-model="reviewText" rows="4" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-300 block w-full sm:text-sm border-gray-200 rounded-md bg-gray-200" placeholder="Write your review..." style="height: 200px; width: 463px; padding: 10px;"></textarea>
                  <div v-if="reviewError" class="text-red-500 text-sm mt-2">
                    Please fill in the review.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button type="button" @click="userHasReview ? updateReview() : submitReview()" class="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-500 text-base font-medium text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
              {{ userHasReview ? 'Update' : 'Submit' }}
            </button>
            <button type="button" @click="closeReviewModal" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
              Cancel
            </button>
            <button v-if="userHasReview" type="button" @click="deleteReview()" class="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Calendar -->
    <div class="modal-wrapper">
      <vue-final-modal v-model="isCalendarModalOpen" :styles="{ modal: 'bg-white p-6 rounded-lg shadow-lg w-96 h-96 mx-auto' }" content-class="custom-modal-content">
        <div class="modal-container">
          <div class="modal">
          <h2 class="px-6 text-2xl font-bold text-gray-800">
            Available days
          </h2>
          <div class="flex flex-col items-start space-y-4 px-6" style="padding-bottom: 1rem;">
            <span>{{ formatDate(spot.availabilityStart) }} to {{ formatDate(spot.availabilityEnd) }}</span>
            <div class="flex items-center">
              <div class="w-7 h-7 rounded-full" style="background-color: #64b6ff33;"></div>
              <span class="ml-4 text-gray-700">Available</span>
            </div>
            <div class="flex items-center">
              <div class="w-7 h-7 rounded-full" style="background-color: #64b6ff33; filter: blur(1px) drop-shadow(0px 0px 0px red);"></div>
              <span class="ml-4 text-gray-700">Booked</span>
            </div>
          </div>

          <vue-cal
            hide-view-selector
            :time="false"
            active-view="month"
            xsmall
            class="vuecal--rounded-theme vuecal--blue-theme"
            :disable-days="dateAttributes"
            :min-date="minDate"
            :max-date="maxDate"
          >
          </vue-cal>

          <div class="px-6 py-4">
              <h2 class="text-2xl font-bold text-gray-800">Book Your Stay At {{ spot.name }}</h2>
              <p class="mt-2 text-gray-600">Select your desired check-in and check-out dates to book your stay with us.</p>
              <div class="mt-4">
                <div class="flex flex-col sm:flex-row sm:space-x-4">
                  <div class="mb-4 sm:mb-0">
                    <label for="startDate" class="block text-sm font-medium text-gray-700">Check-in Date</label>
                    <select v-model="startDate" @change="EndDateOptions" class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md opacity-75" style="background-color: #b2e4eb;">
                      <option v-for="date in AvailableDates" :key="date" :value="date">{{ formatDate(date) }}</option>
                    </select>
                  </div>
                  <div>                    
                    <label for="endDate" class="block text-sm font-medium text-gray-700">Check-out Date</label>
                    <select v-model="endDate" class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md opacity-75" style="background-color: #b2e4eb;">
                      <option v-for="date in endDateOptions" :key="date" :value="date">{{ formatDate(date) }}</option>
                    </select>
                  </div>
                </div>
                <span v-if="errorMessage" class="text-red-500 text-sm mt-2">{{ errorMessage }}</span>
              </div>
            </div>

          <div class="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button type="button" @click="closeCalendarModal" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
              Cancel
            </button>
            <button type="button" @click="handleBooking" class="px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
              Book Now
            </button>
          </div>

        </div>
      </div>
    </vue-final-modal>
    </div>

    <!-- Success Modal -->
    <div v-if="showSuccessModal" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                  Booking Registered
                </h3>
                <div class="mt-2">
                  <p>Your booking has been successfully registered!</p>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button type="button" @click="goToBookings" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
              Go to Bookings Page
            </button>
          </div>
        </div>
      </div>
    </div>

    <ModalsContainer />
  </div>
</template>

<script>
  import { ref, onMounted} from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import axios from '@/api/axios';
  import StarRating from '@/components/StarRating.vue';
  import { VueFinalModal, ModalsContainer } from 'vue-final-modal';
  import VueCal from 'vue-cal';
  import 'vue-cal/dist/vuecal.css';
  import { jwtDecode } from 'jwt-decode';

  export default {
    name: "PageCampingSpot",
    data(){
      return{
        endDateOptions: [],
        errorMessage: '',
        totalPrice: 0,
      }
    },
    computed: {
      minDate() { 
        const currentDate = new Date();
        const availabilityStartDate = new Date(this.spot.availabilityStart);

        if (availabilityStartDate < currentDate) {
          return currentDate;
        } else {
          return availabilityStartDate;
        }
      },
      maxDate() {
        //console.log(this.spot.availabilityEnd)
        return new Date(this.spot.availabilityEnd);
      },
      dateAttributes() {
        const dates = [];
        this.bookingDates.forEach(booking => {
          const checkInDate = new Date(booking.checkInDate);
          const checkOutDate = new Date(booking.checkOutDate);

          for (let d = new Date(checkInDate); d <= checkOutDate; d.setDate(d.getDate() + 1)) {
            dates.push(d.toISOString().split('T')[0]);
            //console.log(d.toISOString().split('T')[0]) 
          }
        });
        return dates;
      },
      AvailableDates() {
        const start = this.minDate;
        const end = this.maxDate;
        
        const bookedSet = new Set(this.dateAttributes.map(date => {
          const d = new Date(date);
          return d.toISOString().split('T')[0]; 
        }));

        const availableDates = [];

        for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
          const currentDateStr = d.toISOString().split('T')[0];
          if (!bookedSet.has(currentDateStr)) {
            availableDates.push(new Date(d));
          }
        }
        
        //console.log(availableDates)
        return availableDates;
      },
    },
    methods: {
      EndDateOptions() {
        //console.log('updateEndDateOptions called');
        if (!this.startDate) {
          this.endDateOptions = [];
          return;
        }
        const start = new Date(this.startDate);
        start.setDate(start.getDate() + 1)
        const end = new Date(this.maxDate);
        end.setDate(end.getDate() +1)
        const bookedSet = new Set(this.dateAttributes.map(date => {
          const d = new Date(date);
          return d.toISOString().split('T')[0];
        }));
        const endDateOptions = [];
        for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
          const currentDateStr = d.toISOString().split('T')[0];
          if (bookedSet.has(currentDateStr)) {
            break;
          }
          endDateOptions.push(new Date(d));
        }
        //console.log('EndDateOptions:', endDateOptions);
        this.endDateOptions = endDateOptions;
      },
      handleBooking() {
        const checkInDate = this.startDate;
        const checkOutDate = this.endDate;

        if (!checkInDate || !checkOutDate) {
          this.errorMessage = "Please select both check-in and check-out date.";
          return;
        }
        this.errorMessage = '';

        this.addBooking(checkInDate, checkOutDate);

        this.closeCalendarModal();
      },
    },
    watch: {
      startDate(newVal) {
        console.log('startDate watcher triggered', newVal);
        this.endDate = null; 
        this.EndDateOptions;
      },
      endDate(newVal) {
        console.log('End Date selected:', newVal);
      },
    },
    components: {
      StarRating,
      VueFinalModal,
      ModalsContainer,
      VueCal,
    },

    setup() {
      const router = useRouter();
      const spot = ref({});
      const comments = ref([]);
      const isLoggedIn = ref(false);
      const isCalendarModalOpen = ref(false);
      const calendarEvents = ref([]);
      const route = useRoute();
      const spotId = route.params.id;

      const showReviewModal = ref(false);
      const reviewText = ref('');
      const selectedStars = ref(0);
      const reviewError = ref(false);
      const userHasReview = ref(false);
      const userId = ref(null);
      const existingReviewId = ref(null);
      const existingRateId = ref(null);
      const bookingDates = ref([]);

      const showSuccessModal = ref(false);

      const goToBookings = () => {
        router.push('/Bookings'); 
        closeSuccessModal(); 
      };

      const closeSuccessModal = () => {
        showSuccessModal.value = false;
      };

      const addBooking = async(checkInDate, checkOutDate) => {
        const start = new Date(checkInDate);
        const end = new Date(checkOutDate);
        const nights = (end - start) / (1000 * 60 * 60 * 24);

        const requestBody = {
          spotId: spotId,
          bookingDate: new Date().toISOString(),
          checkInDate: new Date(checkInDate).toISOString(),
          checkOutDate: new Date(checkOutDate).toISOString(),
          totalPrice: nights * spot.value.price,
          status: status
        }

        console.log('Request Body:', requestBody);

        try {
          const response = await axios.post('/api/Booking/new', requestBody, {
            headers: {
              'Content-Type': 'application/json'
            }
          });

          if (response.status === 200) {
            console.log('Booking successful', response.data);
            showSuccessModal.value = true; // Show the success modal
          }
        }
        catch (error) {
          console.error('Booking failed', error);
          alert('Booking failed', error);
        }
      };
    
      const formatDate = (dateString) => {
        if (!dateString) return '';
        const dateObj = new Date(dateString);
        const day = String(dateObj.getDate()).padStart(2, '0');
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const year = dateObj.getFullYear();
        return `${day}/${month}/${year}`;
      };

      const fetchSpotDetails = async () => {
        try {
          const response = await axios.get(`/api/CampingSpot/${spotId}/details`);
          spot.value = response.data;
        } catch (error) {
          console.error('Failed to fetch spot details:', error);
        }
      };

      const fetchPicturesForSpot = async () => {
        try {
          const response = await axios.get(`/api/Picture/camping-spot/${spotId}`);
          if (spot.value) {
            spot.value.pictures = response.data.map(picture => ({
              ...picture,
              url: `/CampingSpotpicture/${picture.url}`
            }));
            spot.value.currentPictureIndex = 0;
          }
        } catch (error) {
          console.error(`Failed to fetch pictures for spot ${spotId}:`, error);
        }
      };

      const selectStar = (n) => {
        selectedStars.value = selectedStars.value === n ? 0 : n;
      };

      const submitReview = async () => {
        if (!reviewText.value.trim()) {
          reviewError.value = true;
          return;
        }
        reviewError.value = false;

        try {
          await axios.post(`/api/Comment/camping-spot/${spotId}/new`, reviewText.value, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
          await axios.post(`/api/Rating/camping-spot/${spotId}/new`, selectedStars.value, {
            headers: {
              'Content-Type': 'application/json'
            }
          });

          reviewText.value = '';
          selectedStars.value = 0;

          fetchFeedback();
        } catch (error) {
          console.error("Failed to submit review:", error);
        } finally {
          closeReviewModal();
        }
      };

      const updateReview = async () => {
        if (!reviewText.value.trim()) {
          reviewError.value = true;
          return;
        }
        reviewError.value = false;

        try {
          await axios.put(`/api/Comment/${existingReviewId.value}/update`, reviewText.value, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
          await axios.put(`/api/Rating/${existingRateId.value}/update`, selectedStars.value, {
            headers: {
              'Content-Type': 'application/json'
            }
          });

          fetchFeedback();
        } catch (error) {
          console.error("Failed to update review:", error);
        } finally {
          closeReviewModal();
        }
      };

      const deleteReview = async () => {
        try {
          await axios.delete(`/api/Comment/comments/${existingReviewId.value}`);
          await axios.delete(`/api/Rating/comments/${existingRateId.value}`);

          fetchFeedback();
        } catch (error) {
          console.error("Failed to delete review:", error);
        } finally {
          closeReviewModal();
        }
      };

      const openReviewModal = () => {
        const userComment = comments.value.find(comment => String(comment.userId) === String(userId.value));

        if (userComment) {
          reviewText.value = userComment.commentText;
          selectedStars.value = userComment.rating.ratingValue;
          existingReviewId.value = userComment.id;
          userHasReview.value = true;
        } else {
          reviewText.value = '';
          selectedStars.value = 0;
          userHasReview.value = false;
        }
        showReviewModal.value = true;
      };

      const closeReviewModal = () => {
        showReviewModal.value = false;
        selectedStars.value = 0;
        reviewText.value = '';
        reviewError.value = false;
      };

      const nextPicture = () => {
        if (spot.value.pictures) {
          spot.value.currentPictureIndex = (spot.value.currentPictureIndex + 1) % spot.value.pictures.length;
        }
      };

      const previousPicture = () => {
        if (spot.value.pictures) {
          const length = spot.value.pictures.length;
          spot.value.currentPictureIndex = (spot.value.currentPictureIndex - 1 + length) % length;
        }
      };

      const fetchFeedback = async () => {
        try {
          const response = await axios.get(`/api/Comment/camping-spot/${spotId}/feedback`);
          comments.value = response.data;

          const userComment = comments.value.find(comment => String(comment.userId) === String(userId.value));
          userHasReview.value = !!userComment;
          if (userComment) {
            existingReviewId.value = userComment.id;
            existingRateId.value = userComment.rating.ratingId;
            reviewText.value = userComment.commentText;
            selectedStars.value = userComment.rating.ratingValue;
          }
        } catch (error) {
          console.error('Failed to fetch feedback:', error);
        }
      };

      const fetchBookingDates = async () => {
        try {
          const response = await axios.get(`/api/Booking/bookingdates?spotId=${spotId}`);
          bookingDates.value = response.data;
          console.log('Booking Dates:', bookingDates.value); 
        } catch (error) {
          console.error('Failed to fetch booking dates:', error);
        }
      };

      const openCalendarModal = () => {
        isCalendarModalOpen.value = true;
      };

      const closeCalendarModal = () => {
        isCalendarModalOpen.value = false;
      };

      const decodeJWT = (token) => {
        try {
          return jwtDecode(token);
        } catch (error) {
          console.error('Failed to decode JWT:', error);
          return null;
        }
      };

      onMounted(() => {
        fetchSpotDetails();
        fetchPicturesForSpot();
        fetchBookingDates();
        const token = sessionStorage.getItem('authToken');
        if (token) {
          isLoggedIn.value = true;
          const decodedToken = decodeJWT(token);
          if (decodedToken) {
            userId.value = decodedToken.nameid;
          }
        }
        fetchFeedback();
      });

      return {
        spot,
        previousPicture,
        nextPicture,
        comments,
        isLoggedIn,
        isCalendarModalOpen,
        calendarEvents,
        openCalendarModal,
        showReviewModal,
        reviewText,
        selectedStars,
        selectStar,
        submitReview,
        closeReviewModal,
        openReviewModal,
        reviewError,
        userHasReview,
        updateReview,
        deleteReview,
        closeCalendarModal,
        bookingDates,
        formatDate,
        addBooking,
        showSuccessModal,
        goToBookings,
        closeSuccessModal,
      };
    },
  };
</script>

<style>
  .carousel-inner {
    overflow: visible;
    height: 48px; 
  }

  .carousel-item {
    position: absolute;
    width: 100%;
    height: 100%; 
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .carousel-item.active {
    opacity: 1;
  }

  .carousel-control-prev,
  .carousel-control-next {
    top: 50%;
    transform: translateY(-50%);
    background: #174464d9;
    width: 72px;
    border-radius: 10px;
    padding: 2px;
    margin: 10px;
  }

  .spot-actions {
    margin-top: 20px; 
    justify-content: space-between;
    display: flex;
  }

  .text-yellow-500 {
    color: #F59E0B;
  }

  .blur-sm {
    filter: blur(4px);
  }

  /* Customize the calendar container */

  .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed; 
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(107, 114, 128, 0.75);
  }

  .modal {
    background-color: #ffffff;
    width: 900px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 26px;
  }

  .vuecal__cell--today{
    background-color: #17446400;
  }

  .vuecal {
    margin: 0 auto;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: auto;
    height: 390px;
  }

  .vuecal__cell--disabled {
    filter: blur(1px) drop-shadow(0px 0px 0px red);
  }

  /* Customize weekday headers */
  .vuecal__weekdays {
    display: none;
  }

  /* Customize calendar cells */
  .vuecal__cell {
    background-color: #fff;
    color: #333;
    padding: 10px;
    border-bottom: 1px solid #eee;
  }

  /* Highlight selected date */
  .vuecal__cell--selected {
    background-color: #2196f3;
    color: #fff;
  }

  /* Highlight cells with events */
  .vuecal__cell--has-event {
    background-color: #e3f2fd;
  }

  /* Customize events */
  .vuecal__event {
    background-color: #4caf50;
    color: #fff;
    padding: 5px;
    border-radius: 4px;
    margin: 2px 0;
  }
</style>
