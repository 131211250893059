<template>
    <div class="masterdiv flex flex-col items-center bg-transparent text-white">
        <img :src="profilePictureUrl" alt="User's Profile Picture" class="logo mb-4" />
        <h1 class="text-3xl font-bold mb-8">Update Profile</h1>
        <form @submit.prevent="updateProfile" class="w-96 max-w-md">
            <div class="input-box mb-6">
                Username:
                <input
                    type="text"
                    id="userName"
                    name="userName"
                    v-model="userProfile.userName"
                    placeholder="Username"
                    class="input-field w-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-80 rounded-md p-3 text-white"
                    required
                />
            </div>
            <div class="input-box mb-6">
                Email:
                <input
                    type="email"
                    id="email"
                    name="email"
                    v-model="userProfile.email"
                    placeholder="Email"
                    class="input-field w-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-80 rounded-md p-3 text-white"
                    required
                />
            </div>
            <div class="input-box mb-6">
                First name:
                <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    v-model="userProfile.firstName"
                    placeholder="First Name"
                    class="input-field w-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-80 rounded-md p-3 text-white"
                    required
                />
            </div>
            <div class="input-box mb-6">
                Last name:
                <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    v-model="userProfile.lastName"
                    placeholder="Last Name"
                    class="input-field w-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-80 rounded-md p-3 text-white"
                    required
                />
            </div>
            <div class="input-box mb-6">
                Profile picture:
                <input
                    type="file"
                    id="profilePicture"
                    name="profilePicture"
                    @change="onFileChange"
                    class="input-field w-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-80 rounded-md p-3 text-white"
                />
            </div>
            <div class="flex justify-end">
                <button type="submit" class="lgnBtn bg-blue-500 text-white rounded-md p-3 font-bold hover:bg-blue-600">
                    UPDATE PROFILE
                </button>
            </div>
        </form>

        <div v-if="showModal" class="fixed z-10 inset-0 overflow-y-auto">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div class="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div v-if="successMessage" class="text-green-500 mb-4">
                            {{ successMessage }}
                        </div>
                        <div v-else-if="errorMessages.length" class="text-red-500 mb-4">
                            <div v-for="(message, index) in errorMessages" :key="index">
                                {{ message }}
                            </div>
                        </div>
                    </div>
                    <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" @click="showModal = false">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from '@/api/axios';

    export default {
        name: "PageUser",
        data() {
            return {
                userProfile: {
                    userName: '',
                    email: '',
                    firstName: '',
                    lastName: '',
                    profilePicture: null
                },
                initialUserProfile: {
                    userName: '',
                    email: '',
                    firstName: '',
                    lastName: '',
                    profilePicture: null
                },
                errorMessages: [],
                successMessage: '',
                showModal: false,
                change: Boolean,
            };
        },
        created() {
            this.fetchUserProfile().then(() => {
                this.initialUserProfile = {...this.userProfile};
            });
        },
        methods: {
            fetchUserProfile() {
                return axios.get('/api/Users/get-profile')
                .then(response => {
                    console.log('Fetched user profile:', response.data);
                    this.userProfile = response.data;
                })
                .catch(error => {
                    console.error('Failed to fetch user profile:', error);
                });
            },
            updateProfile() {
                if (JSON.stringify(this.userProfile) === JSON.stringify(this.initialUserProfile)) {
                    this.errorMessages = ["No changes detected. Change a value to update the profile."];
                    this.showModal = true;
                    return;
                }

                const formData = new FormData();
                formData.append('userName', this.userProfile.userName);
                formData.append('email', this.userProfile.email);
                formData.append('firstName', this.userProfile.firstName);
                formData.append('lastName', this.userProfile.lastName);

                // Check if there's a file change 
                if (this.change == true) {
                    formData.append('profilePicture', this.userProfile.profilePicture, this.userProfile.profilePicture.name);
                } else{
                    //const blob = this.base64ToBlob(this.userProfile.profilePicture, 'image/png');
                    const blob = this.base64ToBlob(this.userProfile.profilePicture, 'image/jpeg');
                    formData.append('profilePicture', blob, `${this.userProfile.userName}_ProfilePicture.jpg`)
                }

                axios.put('/api/Users/update', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    this.successMessage = 'Profile updated successfully!';
                    this.showModal = true; 
                    console.log('Profile updated successfully:', response.data);
                    this.fetchUserProfile().then(() => {
                        this.initialUserProfile = {...this.userProfile};
                    });
                })
                .catch(error => {
                    console.error('Update failed:', error.response ? error.response.data : error.message);
                    if (error.response && error.response.data) {
                        this.errorMessages = [error.response.data.message]; 
                    } else {
                        this.errorMessages = ['Failed to update profile. Please try again.'];
                    }
                    this.showModal = true;
                });
            },
            onFileChange(e) {
                const files = e.target.files;
                if (files.length === 0) {
                    console.error("No file selected!");
                    return;
                }
                this.userProfile.profilePicture = files[0];  
                this.change = true
            },
            base64ToBlob(base64, mimeType) {
                const byteCharacters = atob(base64.replace(/^data:.+;base64,/, ''));
                const byteArrays = [];

                for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                    const slice = byteCharacters.slice(offset, offset + 512);
                    const byteNumbers = new Array(slice.length);
                    for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    byteArrays.push(byteArray);
                }
                return new Blob(byteArrays, {type: mimeType});
            }
        },
        computed: {
            profilePictureUrl() {
                // Check if the profilePicture is a File object
                if (this.userProfile.profilePicture instanceof File) {
                    return URL.createObjectURL(this.userProfile.profilePicture);
                }

                // Check if profilePicture is already a base64 string or a URL
                else if (typeof this.userProfile.profilePicture === 'string') {
                    return this.userProfile.profilePicture.startsWith('data:image') ? 
                        this.userProfile.profilePicture : 
                        'data:image/png;base64,' + this.userProfile.profilePicture;
                }
                return '../assets/invert_test.png'; 

            }
        },
    };
</script>


<style scoped>
    .masterdiv {
        margin-top: 80px;
    }

    ::placeholder {
        color: rgb(0, 0, 0);
    }

    .input-field {
        background-color: #fdfdfdda;
        border: none;
        color: #000000;
    }
    
    .icon {
        font-size: 20px;
    }
    
    .lgnBtn {
        min-width: unset;
        max-width: unset;
    }

    .logo {
        width: 200px;
        height: 180px;
        margin-bottom: 1rem; 
    }

    .link {
        @apply hover:underline;
        color: #22d3ee; 
    }
</style>
