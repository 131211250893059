<template>
  <div id="app" class="flex flex-col items-center w-full h-screen">
    <div class="w-full">
      <ul class="menu flex justify-start rounded-lg shadow overflow-hidden relative">
        <div class="menu-indicator absolute bottom-0 h-1 transition duration-500"
             :style="{ left: activeRoutePosition + 'px', width: activeRouteWidth + 'px' }">
        </div>
        <li v-for="route in filteredRoutes" :key="route.path">
          <router-link v-if="route.path !== '/'" :to="route.path" custom v-slot="{ navigate, isActive }">
            <div class="menu-link flex items-center px-4 py-3 text-white no-underline"
                 :class="{ 'active': isActive }"
                 @click="navigate"
                 :ref="`routeLink-${route.path}`">
               {{ route.name || username }}
            </div>
          </router-link>
        </li>
      </ul>
      <div class="page-content w-full mt-4 flex justify-center">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
  //import axios from '@/api/axios';

  export default {
    data() {
      return {
        activeRoutePosition: 0,
        activeRouteWidth: 0,
      };
    },
    methods: {
      updateSliderPositionAndWidth() {
        this.$nextTick(() => {
          const activeRouteLink = this.filteredRoutes.find(route => route.path === this.$route.path);
          if (activeRouteLink) {
            const refName = `routeLink-${activeRouteLink.path}`;
            const activeRouteElement = this.$refs[refName];
            if (activeRouteElement && activeRouteElement.length > 0) {
              const rect = activeRouteElement[0].getBoundingClientRect();
              const menuOffsetLeft = this.$el.querySelector('.menu').getBoundingClientRect().left;
              this.activeRoutePosition = rect.left - menuOffsetLeft;
              this.activeRouteWidth = rect.width;
            }
          }
        });
      },
    },
    mounted() {
      this.updateSliderPositionAndWidth();
    },
    computed: {
      username() {
        const user = JSON.parse(localStorage.getItem('user'));
        return user ? user.username : '';
      },
      filteredRoutes() {
        let routes = this.$router.options.routes.filter(route => route.path !== '/' && route.path !== '/User' && route.path !== '/Logout' && route.path !== '/RegisterOwner' && route.path !== '/OwnerOverview' && route.path !== '/Bookings');

        if (this.$route.path !== '/Register') {
          routes = routes.filter(route => route.path !== '/Register');
        }

        if (this.$route.path !== '/CampingSpot/:id') {
          routes = routes.filter(route => route.path !== '/CampingSpot/:id');
        }

        if (sessionStorage.getItem('authToken') != null) {
          routes = routes.filter(route => route.path !== '/Login');
          routes.push({ name: "Bookings", path: '/Bookings'});
          routes.push({ name:"Owned spots", path: '/OwnerOverview'});          
          //Logout and profile need to be last element on the menu
          routes.push({ name: "Profile", path: '/User'});
          routes.push({ name:"Logout →", path: '/Logout'});
        }
        return routes;
      },
    },
    watch: {
      $route() {
        this.updateSliderPositionAndWidth();
      },
    },
  };
</script>
 
<style scoped>
  @import "https://cdn.jsdelivr.net/npm/tailwindcss@^2.0/dist/tailwind.min.css";
    
  .menu {
    @apply flex rounded-lg shadow overflow-hidden relative;
    width: fit-content;  
    margin: 0 auto;    
    background-color: #133655be;
  }

  .menu-link {
    @apply flex items-center px-4 py-3 text-white no-underline;
    cursor: pointer; 
  }
    
  .menu-link:hover, .menu-link.active {
    color: #30e4c3;
  }

  .menu-indicator {
    position: absolute;
    bottom: 0;
    height: 5px;
    background-color: #30e4c3;
    transition: all 0.5s ease;
    width: 0;
  }
    
  #app {
    padding-top: 2%; 
    background-image: url(./Images/bg2.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100vh;
    width: 100vw; 
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1; 

    overflow: auto;
  }
</style>
 